<template>
  <div
    class="tab-pane fade"
    id="pills-success"
    role="tabpanel"
    aria-labelledby="pills-success-tab"
  >
    <table class="customtable w-100">
      <thead>
        <tr>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            customer Id
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            Loan Id
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 30%"
          >
            customer name
          </th>
          <!-- <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            last name
          </th> -->
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            amount received
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            payment date
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 10%"
          >
            cheque number
          </th>
        </tr>
      </thead>
      <tbody v-if="loading && successTransactions.length < 1">
        <tr>
          <td colspan="4">
            <div class="d-flex align-items-center justify-content-center p5">
              <div
                class="spinner-border"
                style="width: 3rem; height: 3rem"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <span
                class="text--black text--600 text--medium d-inline-flex ml-4"
                >Please wait</span
              >
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="successTransactions.length > 0">
        <tr v-for="(success, i) in successTransactions" :key="i">
          <td>
            <span class="table__body__text table__text">{{
              success.customerId
            }}</span>
          </td>

          <td>
            <span class="table__body__text table__text text--capital">{{
              success.loanId
            }}</span>
          </td>

          <td>
            <span class="table__body__text table__text text--capital">{{
              success.customerName ? success.customerName : ""
            }}</span>
          </td>

          <!-- <td>
            <span class="table__body__text table__text text--capital">{{
              success.lastName ? success.lastName : ""
            }}</span>
          </td> -->

          <td>
            <span
              style="color: #076f18"
              class="table__body__text table__text text--capital"
            >
              {{ addCommas(success.amountReceived) }}
            </span>
          </td>

          <td>
            <span class="table__body__text table__text text--capital">{{
              formatDate(success.paymentDate)
            }}</span>
          </td>

          <td>
            <span class="table__body__text table__text">{{
              success.chequeNumber
            }}</span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="12">
            <div
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
                p5
              "
            >
              <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

              <span
                class="d-block text--danger text--600 text--medium mt-3 mb-5"
              >
                No data available</span
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "SuccessfulTransactions",
  props: ["loading", "successTransactions"],

  methods: {
    addCommas(amount) {
      if (amount) {
        let fixedAmount = amount.toFixed(2);
        return fixedAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      } else {
        return "0.00";
      }
    },

    formatDate(data) {
      return moment(data).format("DD-MMM-YYYY");
    },
  },
};
</script>
